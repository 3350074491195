import React, { useReducer } from "react";
import { TeamContext } from "../Contexts.js";
import teamReducer from "./TeamReducer.js";

const TeamState = props => {
    const initialState = {
        teamMembers: [
            {
                id: 2,
                image_path: 'images/angela-headshot.jpg',
                first_name: 'Angela',
                last_name: 'Horton',
                headline: 'Licensed Massage Therapist & Reiki Master',
                description: 'After 25 years at AT&T, Angela followed her passion for healing to become a Licensed Massage Therapist and Reiki Master. As a mother of five, she brings a nurturing and intuitive approach to her work, with a special focus on clients with special needs. Angela offers a holistic approach to wellness, addressing physical, emotional, mental and spiritual aspects of healing. Dedicated to creating a supportive and healing environment, Angela ensures that both her clients and employees thrive in a space designed for growth and well-being.',
            },
            {
                id: 1,
                image_path: 'images/ginger-headshot.jpg',
                first_name: 'Ginger',
                last_name: 'Kratzmeyer',
                headline: 'Licensed Massage Therapist',
                description: 'Ginger, a seasoned Licensed Massage Therapist, is devoted to your well-being and the transformative power of touch. With over 11 years of experience, she has mastered the art of deep-tissue massage, the soothing warmth of hot stone therapy, and the precision of trigger point release. But it’s not just about technique: Ginger’s commitment to exceptional customer service ensures that every client leaves their massage session feeling blissfully relaxed and eager to book their next appointment.',
            },
            {
                id: 4,
                image_path: 'images/megan-headshot.jpg',
                first_name: 'Megan',
                last_name: 'Hubbard',
                headline: 'Licensed Massage Therapist',
                description: 'With a decade of experience in massage therapy, Megan has developed effective treatment plans for patients with chronic pain and back issues. Her expertise includes Swedish, Deep Tissue, Reflexology, and Hot Stone therapies.  Megan thrives on challenges and continually seeks to expand her skills, focusing on conditions such as post-surgery recovery, sciatica, arthritis, fibromyalgia, and tendonitis. She prioritizes open communication and genuine care, always ready to lend a sympathetic ear. Whether in a therapeutic or spa setting, her goal is to provide a relaxing and therapeutic experience tailored to each individual’s needs.',
            },
            {
                id: 5,
                image_path: 'images/misti-headshot.jpg',
                first_name: 'Misti',
                last_name: 'Sterling',
                headline: 'Licensed Massage Therapist',
                description: 'As a skilled and experienced massage therapist with 11 years of practice, I specialize in deep tissue therapy, hot stone treatments, and relaxation techniques to provide a tailored, holistic approach for each client. My approoach combines precision and care to relieve muscle tension, reduce stress, and promote overall well-being. Whether you are seeking relief from chronic pain, or simply looking to unwind, my goal is to provide a calming and restorative environment that helps you achieve optimal balance and relaxation.',
            },
            {
                id: 6,
                image_path: 'images/renee-headshot.jpeg',
                first_name: 'Renee',
                last_name: 'Handley',
                headline: 'Studio Coordinator',
                description: 'A California native now thriving in Texas, Renee brings over two decades of expertise in the beauty and wellness industry. With a strong commitment to customer service, she believes that every client deserves an exceptional experience. Renee’s passion for her work shines through in her interactions, making her a trusted professional dedicated to enhancing the lives of those she serves.'
            },
            {
                first_name: 'Cherie',
                last_name: 'Rhodes',
                headline: 'Licensed Massage Therapist',
                description: ' With 18 years of experience as a licensed massage therapist, I am dedicated to nurturing the whole person: mind, body, and spirit. My approach to massage goes beyond relaxation; it’s about restoring balance, easing tension, and promoting overall well-being. I believe that true healing happens when the physical, mental, and spiritual aspects of a person are in harmony. Through intuitive touch and customized techniques, I create a space where clients can release stress, reconnect with themselves, and find lasting relief. Whether you seek deep therapeutic work or a moment of tranquility, my goal is to support your journey to wellness with compassion and care.',
                image_path: 'images/cherie-headshot.jpg',
            },
            {
                first_name: 'Jamal',
                last_name: 'Douglas',
                headline: 'Licensed Massage Therapist',
                description: 'Jamal is a dedicated Massage Therapist with over two years of experience. He earned his license from Parker University and advanced his training at Texas Woman’s University. Certified in lymphatic drainage and decongestive therapy, Jamal specializes in deep tissue massage, myofascial therapy, and trigger point techniques. He is father to three beautiful children and enjoys watching them excel in sports and spending quality time with family and friends.',
                image_path: 'images/jamal-headshot.jpg',
            },
            {
                first_name: 'Jessica',
                last_name: 'Durbin',
                headline: 'Licensed Massage Therapist',
                description: 'As a long-time resident of Kaufman County and a proud Forney native, I am deeply committed to helping my community relax and feel their best through massage therapy. Whether you prefer gentle pressure or something deeper, I tailor every session to meet your needs. With expertise in a variety of modalities, from Swedish to deep tissue, I’m always expanding my skills to provide the best possible care. Let me help ease your stress and rejuvenate your body—book your session today!',
                image_path: 'images/jessica-headshot.jpg',
            }
        ],
        filtered: null,         // Array of filtered therapists
        current: null
    };

    const [state, dispatch] = useReducer(teamReducer, initialState);

    // Load Team Members
    const loadTeamMembers = async () => {
        await dispatch({
            type: 'TEAM_MEMBERS_LOADED',
            payload: initialState.teamMembers
        });
    };

    return (
        <TeamContext.Provider
            value={{
                teamMembers: state.teamMembers,
                current: state.current,
                filtered: state.filtered,
                loadTeamMembers
            }}>
            {props.children}
        </TeamContext.Provider>
    );
};

export default TeamState;