import React from "react";
import "../../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="center-text">
          <h4>Business Hours:</h4>
          <p>
            9am - 9pm 7 days a week. (Mon-Sat 6pm-9pm and all day Sunday by
            appointment only)
          </p>
        </div>
      </div>
      <br />
      <p>
        Massage Establishment License - <span className="bold">ME9070</span>
      </p>
      <br />
      <p>&copy; 2024 Touch Massage</p>
    </footer>
  );
};

export default Footer;
