import React, { useContext, useEffect } from "react";
import { TeamContext } from "../../context/Contexts";
import TeamMemberItem from "./TeamMemberItem";
import { Helmet } from "react-helmet";

const TeamMemberList = () => {
  const teamContext = useContext(TeamContext);
  const { teamMembers, loadTeamMembers } = teamContext;

  useEffect(() => {
    loadTeamMembers();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Team</title>
        <link rel="canonical" href="https://touch-massagetherapy.com/team" />
      </Helmet>
      <h1 className="center-text fancy-font glow big-text-1">Meet Our Team</h1>
      <div className="team-member-list">
        {teamMembers
          .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
          .map((member) => (
            <TeamMemberItem key={member.id} member={member} />
          ))}
      </div>
    </>
  );
};

export default TeamMemberList;
