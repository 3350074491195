import React from "react";

export const AddonItem = ({ item }) => {
  return (
    <details>
      <summary>
        ${item.price} - {item.title}
      </summary>
      <article>
        {item.description}{" "}
        {item.options && item.options.length > 0 && (
          <ul className="addon-options">
            {item.options
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((option, index) => (
                <li key={index}>
                  <span className="bold">{option.title}:</span>
                  &nbsp; {option.description}
                </li>
              ))}
          </ul>
        )}
      </article>
    </details>
  );
};

export default AddonItem; // Ensure the component is exported correctly
