import { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <a href="/">
        <img
          src={require("../../images/logo.webp")}
          alt="Touch Massage Therapy Logo"
          fetchpriority="high"
          className="logo"
        />
      </a>
      <button className="menu-btn" onClick={togglePopup}>
        <i className="fas fa-bars"></i>
      </button>
      <div className={`nav-elements${isOpen ? " open" : ""}`}>
        <div className="navbar-links">
          <Link to="/team" onClick={togglePopup}>
            Our Team
          </Link>
          <Link to="/services" onClick={togglePopup}>
            Massages
          </Link>
          <Link to="/facials" onClick={togglePopup}>
            Facials
          </Link>
          <Link to="/addons" onClick={togglePopup}>
            Add-Ons
          </Link>
          <Link to="/jobs" onClick={togglePopup}>
            Jobs
          </Link>
          <a href="/#testimonials" onClick={togglePopup}>
            Testimonials
          </a>
        </div>
        <div className="social-links">
          <a
            href="https://www.facebook.com/profile.php?id=61565733715315"
            aria-label="Follow us on Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook fa-2x"></i>
          </a>
          <a
            href="https://www.instagram.com/touchmassage.therapy"
            aria-label="Follow us on Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram fa-2x"></i>
          </a>
        </div>
        <button>
          <a href="/policies">Book Now</a>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
