import React, { useContext, useEffect } from "react";
import MassageItem from "./MassageItem";
import { ServiceContext } from "../../context/Contexts";
import "../../css/Services.css";
import { Helmet } from "react-helmet";

export const Services = () => {
  const serviceContext = useContext(ServiceContext);
  const { services, loadServices } = serviceContext;

  useEffect(() => {
    loadServices(); // Load services when the component mounts

    // eslint-disable-next-line
  }, []); // Empty dependency array to run only once

  return (
    <>
      <Helmet>
        <title>Massages</title>
        <link
          rel="canonical"
          href="https://touch-massagetherapy.com/services"
        />
      </Helmet>

      <h1 className="center-text glow fancy-font big-text-1">Massages</h1>
      <p className="center-text bold callout">
        *Add an additional 15 minutes: $22.50 Couples: $45{" "}
      </p>

      <div className="services-container">
        {services
          .sort((a, b) => (a.title > b.title ? 1 : -1)) // Sort services by title
          .map((item) => (
            <MassageItem key={item.id} item={item} />
          ))}
      </div>

      <br />
    </>
  );
};

export default Services;
