import React from "react";

const MassageItem = ({ item }) => {
  return (
    <details>
      <summary>{item.title}</summary>
      <article>{item.description}</article>
      <div>
        <ul>
          {item.price30 && (
            <li>
              <span className="bold">30 min: </span>${item.price30}
            </li>
          )}
          {item.price60 && (
            <li>
              <span className="bold">60 min: </span>${item.price60}
            </li>
          )}
          {item.price90 && (
            <li>
              <span className="bold">90 min: </span>${item.price90}
            </li>
          )}
          {item.price120 && (
            <li>
              <span className="bold">120 min: </span>${item.price120}
            </li>
          )}
        </ul>
      </div>
    </details>
  );
};

export default MassageItem;
