import React from "react";
import { Link } from "react-router-dom";
import Popup from "./Popup";
import Reviews from "./Reviews";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Touch Massage</title>
        <link rel="canonical" href="https://touch-massagetherapy.com/" />
      </Helmet>

      <Popup trigger={true}>
        <header>
          <h2 className="center-text">🙌 Therapist Spotlight 🙌</h2>
        </header>
        <div>
          <img
            src="./images/megan-headshot.jpg"
            className="round-img float-left"
            alt="Team Member"
          />
          <h2 className="glow">Megan Hubbard</h2>
          <p>
            We would like to shine a spotlight on Megan this month due to her
            amazing talent, great attitude, and exceptional customer service.
            Megan specializes in therapeutic massage and reflexology, bringing
            skill and care to every session. Book with Megan in April and enjoy
            a <span className="glow">free aromatherapy upgrade!</span>
          </p>
        </div>
      </Popup>

      <section id="main">
        <h1 className="fancy-font fancy-bold big-text-1">Relax</h1>
        <span className="indent-1 fancy-font fancy-bold big-text-1">
          Rejuvenate
        </span>
        <h1 className="indent-2 fancy-font fancy-bold big-text-1">
          Relieve your tension
        </h1>
        <div className="main-buttons">
          <button>
            <a href="/policies">Book Now</a>
          </button>
          <button>
            <Link
              to="https://go.booker.com/location/TouchMassageL53735/buy/gift-certificate"
              target="_blank"
            >
              Gift Certificates
            </Link>
          </button>
        </div>
      </section>

      <section id="testimonials">
        <div className="testimonials-header">
          Customize your therapy session, massage, facial, or exfoliating
          session to create your very own unique experience
        </div>
        <h1 className="center-text fancy-font big-text-2">
          What our clients are saying about us:
        </h1>
        <Reviews />
        <div className="testimonials-footer">
          <div>
            <h4>Book online or call us at</h4>
            <span className="big-text-3">469-520-0012</span>
          </div>

          <button>
            <a href="/policies">Book Now</a>
          </button>
          <address>
            <span className="bold">Touch Massage</span>
            <br />
            522 Pinson Rd.
            <br />
            Forney, TX 75126
            <br />
            Phone: <a href="tel:469-520-0012">(469) 520-0012</a>
            <br />
            <a href="mailto:info@touch-massagetherapy.com">
              {" "}
              info@touch-massagetherapy.com
            </a>
          </address>
        </div>
      </section>
    </>
  );
};

export default Home;
